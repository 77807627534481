import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["rows"]

  initialize() {
    // console.log("INIT RFQS STIMULUS")
  }

  connect() {
    // console.log("Hello, Stimulus!", this.element)
  }

  toggle() {
    this.request = new Request(event.target.dataset.rfqsUrl);
    this.fetchContent(this.request);
  }

  fetchContent(request) {
    fetch(request)
      .then((response) => {
        if (response.status == 200) {
          response.text().then((text) => this.rowsTarget.innerHTML = text);
        } else {
          console.log("Couldn't load data");
        }
      })
  }
}
