let ep_currency_to_number = function (number) {
  let t = typeof number;
  if (t == 'string') {
    if (number.length == 0) { return 0; }
    number = number.replaceAll(",", "")
  } else {
    number = String(number);
  }
  return parseFloat(parseFloat(number).toFixed(2));
}
window.ep_currency_to_number = ep_currency_to_number;

let ep_number_to_currency = function (number, maximumFractionDigits=2) {
  let t = typeof number;
  if (t == 'string') {
    if (number.length == 0) { return ""; }
    // Supports negative sign at the start of the number
    if (number == "-") { return "-"; }
    number = number.replaceAll(",", "")
  } else {
    number = String(number);
  }
  // console.log("NUMBER:", number);

  let formatter = new Intl.NumberFormat('en-US', {
    style: "decimal",
    maximumFractionDigits: maximumFractionDigits,
  });
  let formatted = formatter.format(number);

  if (formatted == "NaN") {
    return "NaN";
  }
  // console.log("FORRMATTED", formatted);
  let ends_with_comma = number.endsWith(".");
  if (ends_with_comma && maximumFractionDigits > 0) {
    formatted = formatted + ".";
  }
  return formatted;
}
window.ep_number_to_currency = ep_number_to_currency;

document.addEventListener('DOMContentLoaded', initFns);
document.addEventListener('turbo:render', initFns);
function initFns(event) {
  document.removeEventListener('DOMContentLoaded', initFns);

  $(".ep-currency-input").on('input', function(e) {
    let maximumFractionDigits = $(this).data('max-fraction-digits');
    let formatted;
    if (typeof maximumFractionDigits == 'number') {
      formatted = ep_number_to_currency($(this).val(), maximumFractionDigits);
    } else {
      formatted = ep_number_to_currency($(this).val());
    }

    if (formatted == "NaN") {
      let cached = $(this).data('cached-number') ?? "";
      $(this).val(cached);
    } else {
      $(this).val(formatted);
      $(this).data('cached-number', formatted);
    }
  })
};
