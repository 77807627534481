import { Controller } from "@hotwired/stimulus"
// import { MDCSelect } from "@material/select";

export default class extends Controller {
  static targets = ["table", "header"]

  initialize() {
    // console.log("INIT VIRTUAL BIDS STIMULUS")
  }

  connect() {
    // console.log("Hello, Stimulus!", this.element)
  }

  fetchScrapedItems() {
    // console.log(event.target.dataset.url)
    this.request = new Request(event.target.dataset.url);
    this.fetchContent(this.request);
  }

  fetchContent(request) {
    fetch(request)
      .then((response) => {
        if (response.status == 200) {
          response.text().then((text) => {
            let res = JSON.parse(text);

            this.tableTarget.innerHTML = res.content;
            this.headerTarget.innerHTML = res.header;
            $("#scraped-item-modal").modal('show');
          });
        } else {
          console.log("Couldn't load data");
        }
      })
  }
}
