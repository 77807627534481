document.addEventListener('DOMContentLoaded', initFns);
document.addEventListener('turbo:render', initFns);
function initFns(event) {
  document.removeEventListener('DOMContentLoaded', initFns);

  $(".add_fields").click(function(e) {
    e.preventDefault()
    let link = this;
    let time = new Date().getTime();
    let linkId = link.dataset.id;
    let regexp = linkId ? new RegExp(linkId, 'g') : null;
    let newField = regexp ? link.dataset.fields.replace(regexp, time) : null;

    let containerId = "list-container";
    let customContainerId = this.dataset.containerId;
    if (customContainerId) {
      containerId = customContainerId
    }
    $("#" + containerId).append(newField);
    // Reset and reapply remove button handler
    $(".remove_fields").off();
    $(".remove_fields").click(function(e) {
      removeNestedField(this, e);
    });
    // We really need to move this away from this code
    // -erwin 15 May 2022
    $(".nested-fields .ep-currency-input").off();
    $(".nested-fields .ep-currency-input").on('input', function(e) {
      let maximumFractionDigits = $(this).data('max-fraction-digits');
      let formatted;
      if (typeof maximumFractionDigits == 'number') {
        formatted = ep_number_to_currency($(this).val(), maximumFractionDigits);
      } else {
        formatted = ep_number_to_currency($(this).val());
      }

      if (formatted == "NaN") {
        let cached = $(this).data('cached-number') ?? "";
        $(this).val(cached);
      } else {
        $(this).val(formatted);
        $(this).data('cached-number', formatted);
      }
    })
  });

  function removeNestedField(link, e) {
    e.preventDefault();

    let fieldParent = link.closest('.nested-fields');
    // We need to mark the fields as not required first to avoid chrome validation error
    let inputs = fieldParent.getElementsByTagName("input");
    Array.from(inputs).forEach(function(el) {
      el.required = false;
    });

    let deleteField = fieldParent ? fieldParent.querySelector('input[type="hidden"]') : null;
    if (deleteField) {
      deleteField.value = 1;
      fieldParent.style.display = 'none';
    }

    if ($('.nested-fields:visible').length == 0) {
      $(".add_fields").click();
    }
  }
  $(".remove_fields").click(function(e) {
    removeNestedField(this, e);
  });
};
