import { Controller } from "@hotwired/stimulus"
import { MDCSelect } from "@material/select";

export default class extends Controller {
  static targets = ["rows", "pagination"]

  initialize() {
    // console.log("INIT RFQS STIMULUS")
  }

  connect() {
    // console.log("Hello, Stimulus!", this.element)
  }

  toggle() {
    this.request = new Request(event.target.dataset.rfqsUrl);
    this.fetchContent(this.request);
  }

  fetchContent(request) {
    fetch(request)
      .then((response) => {
        if (response.status == 200) {
          response.text().then((text) => {
            // console.log("RESPONSE TEXT:", text);
            let res = JSON.parse(text);
            this.rowsTarget.innerHTML = res.content;
            if (res.pagination) {
              this.paginationTarget.innerHTML = res.pagination;
              //
              let el = document.querySelector("#per-page-select");
              let select = new MDCSelect(el);
              select.listen('MDCSelect:change', () => {
                let selectName = el.dataset.selectName;
                let form = document.getElementById("rfq-pagination-form");
                let per_page_field = form.querySelector('#per-page-field');
                per_page_field.value = parseInt(select.value);
                form.submit();
              });
            } else {
              this.paginationTarget.innerHTML = "";
            }
          });
        } else {
          console.log("Couldn't load data");
        }
      })
  }
}
